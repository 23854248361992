<template>

	<CargaInicial v-if="estadoGrupoDeEntes.listando"></CargaInicial>

	<div v-else class="uk-child-width-1-2@s uk-grid-match" uk-grid>
		<div
			v-for="(g, i) in estadoGrupoDeEntes.listado"
			:key="`gde-${i}`"
		>
			<div
				@click="abrirEdicion(i)"
				class="uk-card uk-card-primary uk-card-hover uk-card-body uk-light"
			>
				<h3 class="uk-card-title">{{ g.descripcion }}</h3>
				<p>{{ g.identificador }}</p>
			</div>
		</div>
		<div>
			<div
				@click="abrirCreacion"
				class="uk-card uk-card-default uk-card-hover uk-card-body"
			>
				<p></p>
				<h3 class="uk-card-title uk-text-bold"><span uk-icon="icon: plus; ratio: 2" class="uk-text-warning"></span> Agregar nuevo grupo de entes</h3>
				<p></p>
			</div>
		</div>
	</div>

	<div id="dialogo-grupo-de-entes" class="uk-flex-top" uk-modal>
		<div class="uk-modal-dialog uk-margin-auto-vertical">
			<button class="uk-modal-close-default" type="button" uk-close></button>
			<div class="uk-modal-header">
				<h2 class="uk-modal-title">Grupo de entes</h2>
			</div>
			<div class="uk-modal-body uk-form-stacked">
				<div class="uk-margin">
					<label class="uk-form-label" for="form-stacked-text">Identificador</label>
					<div class="uk-form-controls">
						<input class="uk-input" type="text" placeholder="Identificador" v-model="grupoDeEntes.identificador" :disabled="seleccionado >= 0">
					</div>
				</div>
				<div class="uk-margin">
					<label class="uk-form-label" for="form-stacked-text">Descripción</label>
					<div class="uk-form-controls">
						<input class="uk-input" type="text" placeholder="Descripción" v-model="grupoDeEntes.descripcion">
					</div>
				</div>
			</div>
			<div class="uk-modal-footer uk-text-right">
				<button class="uk-button uk-button-default uk-modal-close" type="button" :disabled="estadoGrupoDeEntes.guardando">Cancelar</button>
				&nbsp;
				<button v-if="seleccionado < 0" class="uk-button uk-button-primary" type="button" @click="crearGrupoDeEntes">Guardar</button>
				<button v-else class="uk-button uk-button-primary" type="button" @click="editarGrupoDeEntes">Guardar</button>
			</div>
		</div>
	</div>

</template>

<script lang="ts">
import { defineComponent, ref, reactive, getCurrentInstance } from 'vue'
import CargaInicial from '@/views/CargaInicial.vue'
import {
	IGrupoDeEntes,
	estadoGrupoDeEntes,
	crearGrupoDeEntes,
	editarGrupoDeEntes,
	listarGruposDeEntesUnaVez
} from '@/gruposDeEntes'
import { notificación } from '@/utilidades';

export default defineComponent({
  name: 'GruposDeEntes',
  components: {
    CargaInicial
  },
	setup() {
		const app = getCurrentInstance()
		if (!app) {
			window.alert('Ocurrió un error. Comunique este código de error al administrador: 74021704.')
			return
		}

		let UIkit = app.appContext.config.globalProperties.$window.UIkit

		listarGruposDeEntesUnaVez()

		let grupo: IGrupoDeEntes = reactive({
			identificador: '',
			descripcion: '',
			reglas: {}
		})

		let seleccionado = ref(-1)

		let abrirCreacion = function() {
			limpiar()
			seleccionado.value = -1
			UIkit.modal('#dialogo-grupo-de-entes').show()
		}

		let abrirEdicion = function(i: number) {
			if (i < 0 || i > estadoGrupoDeEntes.listado.length-1) {
				let msj = 'Algo salió mal. Código de error: 573200143'
				console.error(msj, i)
				notificación.error(msj)
				return
			}

			limpiar()
			seleccionado.value = i
			grupo.identificador = estadoGrupoDeEntes.listado[i].identificador
			grupo.descripcion = estadoGrupoDeEntes.listado[i].descripcion
			UIkit.modal('#dialogo-grupo-de-entes').show()
		}

		let limpiar = function() {
			grupo.identificador = ''
			grupo.descripcion = ''
		}

		let crear = function() {
			crearGrupoDeEntes(grupo).then(() => {
				UIkit.modal('#dialogo-grupo-de-entes').hide()
				limpiar()
			})
		}

		let editar = function() {
			editarGrupoDeEntes(grupo.identificador, grupo).then(() => {
				UIkit.modal('#dialogo-grupo-de-entes').hide()
				limpiar()
			})
		}

		return {
			seleccionado,
			grupoDeEntes: grupo,
			estadoGrupoDeEntes: estadoGrupoDeEntes,
			crearGrupoDeEntes: crear,
			editarGrupoDeEntes: editar,
			abrirCreacion,
			abrirEdicion
		}
	}
});
</script>

<style scoped>

.uk-card-body {
	cursor: pointer;
}

</style>
