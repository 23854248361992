import { reactive } from 'vue'
import firebase from 'firebase'
import { POST, PUT } from './global'
import { notificación, obtenerListado, texto } from './utilidades'
import { RefCuenta } from './cuenta'

export interface IGrupoDeEntes {
	identificador: string
	descripcion: string
	reglas: any
}

interface IEstadoGrupoDeEntes {
	guardando: boolean
	listando: boolean
	listado: IGrupoDeEntes[]
	listadoInicializado: boolean
}

export let estadoGrupoDeEntes: IEstadoGrupoDeEntes = reactive({
	guardando: false,
	listando: false,
	listado: [],
	listadoInicializado: false
})

export const crearGrupoDeEntes = async function(datos: IGrupoDeEntes) {
	return guardarGrupoDeEntes(POST, '/grupos-de-entes', datos)
}

export const editarGrupoDeEntes = async function(identificador: string, datos: IGrupoDeEntes) {
	return guardarGrupoDeEntes(PUT, `/grupos-de-entes/${identificador}`, datos)
}

const guardarGrupoDeEntes = async function(
	metodo: (recurso: string, datos: any) => Promise<{identificador: string}>,
	recurso: string,
	datos: IGrupoDeEntes
) {
	if (!validarGrupoDeEntes(datos)) {
		return Promise.reject()
	}

	estadoGrupoDeEntes.guardando = true

	try {
		await metodo(recurso, datos)
		notificación.correcto('¡Grupo de entes guardado!')
	} catch (e) {
		estadoGrupoDeEntes.guardando = false
		return Promise.reject()
	}

	estadoGrupoDeEntes.guardando = false

	listarGruposDeEntes()
}

const listarGruposDeEntes = async function() {
	let ref = RefCuenta.collection('gruposDeEntes')

	let constructor = function(d: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>): IGrupoDeEntes {
		let dts = d.data()
		let descripcion = ''

		try {
			descripcion = texto.opcional(dts.descripcion, 'Descripción errada.')
		} catch (e) {
			console.error(e)
		}

		let datos: IGrupoDeEntes = {
			identificador: d.id,
			descripcion: descripcion,
			reglas: dts.reglas
		}

		return datos
	}

	return obtenerListado(ref, constructor, estadoGrupoDeEntes)
}

export const listarGruposDeEntesUnaVez = async function() {
	if (estadoGrupoDeEntes.listadoInicializado) {
		return Promise.resolve()
	}

	return listarGruposDeEntes()
}

function validarGrupoDeEntes(datos: IGrupoDeEntes): boolean {
	if (!datos.identificador) {
		notificación.error('Falta el identificador')
		return false
	} else if (!datos.descripcion) {
		notificación.error('Falta la descripción')
		return false
	}

	return true
}