
import { defineComponent, ref, reactive, getCurrentInstance } from 'vue'
import CargaInicial from '@/views/CargaInicial.vue'
import {
	IGrupoDeEntes,
	estadoGrupoDeEntes,
	crearGrupoDeEntes,
	editarGrupoDeEntes,
	listarGruposDeEntesUnaVez
} from '@/gruposDeEntes'
import { notificación } from '@/utilidades';

export default defineComponent({
  name: 'GruposDeEntes',
  components: {
    CargaInicial
  },
	setup() {
		const app = getCurrentInstance()
		if (!app) {
			window.alert('Ocurrió un error. Comunique este código de error al administrador: 74021704.')
			return
		}

		let UIkit = app.appContext.config.globalProperties.$window.UIkit

		listarGruposDeEntesUnaVez()

		let grupo: IGrupoDeEntes = reactive({
			identificador: '',
			descripcion: '',
			reglas: {}
		})

		let seleccionado = ref(-1)

		let abrirCreacion = function() {
			limpiar()
			seleccionado.value = -1
			UIkit.modal('#dialogo-grupo-de-entes').show()
		}

		let abrirEdicion = function(i: number) {
			if (i < 0 || i > estadoGrupoDeEntes.listado.length-1) {
				let msj = 'Algo salió mal. Código de error: 573200143'
				console.error(msj, i)
				notificación.error(msj)
				return
			}

			limpiar()
			seleccionado.value = i
			grupo.identificador = estadoGrupoDeEntes.listado[i].identificador
			grupo.descripcion = estadoGrupoDeEntes.listado[i].descripcion
			UIkit.modal('#dialogo-grupo-de-entes').show()
		}

		let limpiar = function() {
			grupo.identificador = ''
			grupo.descripcion = ''
		}

		let crear = function() {
			crearGrupoDeEntes(grupo).then(() => {
				UIkit.modal('#dialogo-grupo-de-entes').hide()
				limpiar()
			})
		}

		let editar = function() {
			editarGrupoDeEntes(grupo.identificador, grupo).then(() => {
				UIkit.modal('#dialogo-grupo-de-entes').hide()
				limpiar()
			})
		}

		return {
			seleccionado,
			grupoDeEntes: grupo,
			estadoGrupoDeEntes: estadoGrupoDeEntes,
			crearGrupoDeEntes: crear,
			editarGrupoDeEntes: editar,
			abrirCreacion,
			abrirEdicion
		}
	}
});
